<template>
  <div>
    <headers />
    <div class="mb-30">
      <div class="relative" style="opacity: 1">
        <div data-testid="hero-video-bg" class="top-0 left-0 z-[-1] h-full w-full relative md:absolute">
          <div class="relative w-full h-full overflow-hidden max-h-[80vh] h-[520px] md:h-[650px] md:max-h-full w-full">
            <div autoplay="" style="width: 100%; height: 100%">
              <img style="width: 100%; height: 100%;" :src="require('@/assets/images/DigitalInfrastructure/b1.png')" alt="" />
            </div>
          </div>
        </div>
        <div
          class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] relative md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:h-[650px]"
        >
          <div
            class="col-end-flex flex flex-col col-start-2 col-span-7 md:col-start-2 md:col-span-15 lg:col-start-2 h-full justify-center z-1"
          >
            <div class="relative my-8 md:my-5 flex flex-col items-start text-left content-start justify-start">
              <h1 class="text-2xl md:text-4xl text-iblack-700 dark:text-iblack-300 max-w-[500px]">
                Digital Infrastructure
              </h1>
              <p class="text-lg text-iblack-600 dark:text-iblack-400 mt-2.5 md:mt-2.5 max-w-[500px]">
                A joint venture of ZEN CAPITAL Capital and Iron Point Partners,<br class="hidden md:inline" />
                IPI Partners is dedicated to building the essential underpinnings<br class="hidden md:inline" />
                of the data economy.
              </p>
            </div>
          </div>
        </div>
        <div class="main-grid">
          <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block md:hidden">
            <div
              role="separator"
              aria-orientation="horizontal"
              class="w-full h-full block"
              style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div style="overflow-x: hidden">
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <section class="">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      style="opacity: 1; transform: none"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/DigitalInfrastructure/b2.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-5">
                        <h1
                          class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          style="opacity: 1; transform: none"
                        >
                          Overview
                        </h1>
                        <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                          IPI Partners leverages the combined leadership, strategic experience, and visionary global
                          network of its sponsoring firms.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <a
                    draggable="false"
                    target="_blank"
                    class="opacity-100 transition-hover duration-300 hover:opacity-80 focus:opacity-80"
                    href="https://ipipartners.com"
                    ><section class="">
                      <div
                        class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                        style="opacity: 1; transform: none"
                      >
                        <img style="width: 100%; height: 100%;" :src="require('@/assets/images/DigitalInfrastructure/b3.png')" alt="" />
                      </div>
                      <div class="block md:grid md:grid-cols-6 md:gap-4">
                        <div class="col-span-5">
                          <h1
                            class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                            style="opacity: 1; transform: none"
                          >
                            IPI Partners
                          </h1>
                          <p class="text-iblack-600 mt-2.5" style="opacity: 1; transform: none">
                            Over the past five years, IPI Partners has built one of the largest privately held
                            hyperscale and enterprise data center portfolios in the world with the ability to support
                            over 1.3 Gigawatts of IT capacity.
                          </p>
                          <div class="mt-22" style="opacity: 1; transform: none">
                            <span
                              class="inline-flex items-center button-target transition-opacity duration-400 opacity-100 hover:opacity-80"
                              ><span
                                >
                                <img style="width: 24px; height: 24px;" :src="require('@/assets/images/DigitalInfrastructure/right-circle.png')" alt="" />
                               
                                </span>
                              <span
                                class="text-iblack-700 dark:text-iwhite-100 md:text-iblack-700 dark:text-iwhite-100 ml-3"
                              >
                                Visit IPI Partners
                              </span></span
                            >
                          </div>
                        </div>
                      </div>
                    </section></a
                  >
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div
          class="relative transition-opacity duration-300 overflow-hidden col-start-1 col-span-full"
          style="transform: translateY(40px) translateZ(0px)"
        >
          
          <img style="width: 100%; height: 100%" :src="require('@/assets/images/DigitalInfrastructure/b4.png')" alt="" />
          <div
            class="grid grid-cols-[35px_repeat(3,_1fr_20px)_1fr_35px] md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr] md:absolute md:top-0 md:left-0 md:w-full"
          >
            <div
              class="col-end-flex flex flex-col z-1 col-start-2 col-span-15 md:col-start-2 lg:col-start-2 justify-start mt-[30px] md:mt-[40px] mb-[30px] md:my-0"
            >
              <div class="relative flex flex-col justify-between md:h-full my-0">
                <div>
                  <h1 class="text-2xl text-iblack-700 dark:text-iblack-300 md:dark:text-iblack-700">
                    Digital Infrastructure Assets
                  </h1>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div class="inline-flex justify-center items-center main-grid--in-grid w-full h-[0.5px] block md:hidden">
          <div
            role="separator"
            aria-orientation="horizontal"
            class="w-full h-full block"
            style="background-color: rgb(155, 155, 155); opacity: 1; transform: none"
          ></div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div class="flex items-center main-grid--in-grid justify-left">
                  <div class="block md:grid md:grid-cols-6 md:gap-4 w-full">
                    <div class="col-span-5">
                      <div
                        class="prose max-w-none prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 prose-strong:font-normal md:prose-h1:text-4xl prose-h1:text-2xl md:prose-h2:text-3xl prose-h2:text-xl md:prose-h3:text-2xl prose-h3:text-xl md:prose-h4:text-xl prose-h4:text-lg md:prose-h5:text-lg prose-h5:text-lg prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h1:font-normal prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h2:font-normal prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h3:font-normal prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h4:font-normal prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-h5:font-normal prose-li:marker:text-iblack-600 dark:prose-li:marker:text-iwhite-100 prose-a:dark:text-iblack-300 prose-h1:text-iblack-700 dark:prose-h1:text-iwhite-100 prose-h2:text-iblack-700 dark:prose-h2:text-iwhite-100 prose-h3:text-iblack-700 dark:prose-h3:text-iwhite-100 prose-h4:text-iblack-700 dark:prose-h4:text-iwhite-100 prose-h5:text-iblack-700 dark:prose-h5:text-iblack-300 prose-li:marker:text-iblack-600 prose-a:dark:text-iblack-300 prose-p:text-iblack-600 dark:prose-p:text-iblack-400 prose-strong:text-iblack-800 dark:prose-strong:text-iblack-300 md:text-left"
                        style="transform: translateY(20px) translateZ(0px)"
                      >
                        <h4>Property Photos</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div data-testid="gallery-wrapper" class="main-grid--in-grid overflow-hidden">
          <div class="relative overflow-hidden flex justify-center items-center h-[500px] lg:h-[650px]">
            <div
              class="absolute rounded w-full h-full top-0 left-0 will-change-[opacity]"
              data-testid="gallery-image-1"
              style="opacity: 1"
            >
              <img style="width: 100%; height: 100%" :src="require('@/assets/images/DigitalInfrastructure/b5.png')" alt="" />
            </div>
            <button
              data-testid="gallery-prev"
              aria-label="Previous slide"
              class="absolute rounded z-10 w-1/3 h-full left-0 bottom-0 cursor-pointer bg-gradient-to-r from-gray-700 via-gray-900 transition-opacity duration-300 opacity-0 hover:opacity-30 focus:opacity-30"
            ></button
            ><button
              data-testid="gallery-next"
              aria-label="Next slide"
              class="absolute rounded z-10 w-1/3 h-full right-0 bottom-0 cursor-pointer bg-gradient-to-l from-gray-700 via-gray-900 transition-opacity duration-300 opacity-0 hover:opacity-30 focus:opacity-30"
            ></button>            
            
          </div>
          <div class="relative flex justify-between pt-[20px]">
            <p class="text-iblack-600 dark:text-iblack-400 text-sm" style="opacity: 1">Memorex Rendering</p>
            
          </div>
        </div>
      </div>
      
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="main-grid--in-grid">
                <h1
                  class="text-iblack-700 dark:text-iblack-300 mb-30 text-lg md:text-2xl"
                  style=" transform: translateY(20px) translateZ(0px)"
                >
                  Sustainability
                </h1>
              </div>
              <div class="pb-[30px] md:pb-0 col-span-11 col-start-2 relative">
                <div>
                  <section class="">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      style=" transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/DigitalInfrastructure/b7.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-5">
                        <h1
                          class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          style=" transform: translateY(20px) translateZ(0px)"
                        >
                          Toward A Greener Digital Infrastructure
                        </h1>
                        <p
                          class="text-iblack-600 mt-2.5"
                          style=" transform: translateY(20px) translateZ(0px)"
                        >
                          Environmental efficiency lies at the heart of our infrastructure innovation.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="col-span-11 col-start-14 relative">
                <div>
                  <section class="">
                    <div
                      class="relative overflow-hidden rounded h-[216px] lg:h-[291px]"
                      style=" transform: translateY(20px) translateZ(0px)"
                    >
                      <img style="width: 100%; height: 100%;" :src="require('@/assets/images/DigitalInfrastructure/b8.png')" alt="" />
                    </div>
                    <div class="block md:grid md:grid-cols-6 md:gap-4">
                      <div class="col-span-5">
                        <h1
                          class="text-iblack-700 dark:text-iblack-300 mt-30 text-lg lg:text-2xl"
                          style=" transform: translateY(20px) translateZ(0px)"
                        >
                          Sustainable Grid Mix
                        </h1>
                        <p
                          class="text-iblack-600 mt-2.5"
                          style=" transform: translateY(20px) translateZ(0px)"
                        >
                          We support new sustainable energy mix options across the globe.
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-grid mb-30">
        <div class="main-grid--full-grid">
          <div class="flex flex-col relative">
            <div
              class="block md:grid md:grid-cols-[70px_repeat(11,_1fr_20px)_1fr_70px] xl:grid-cols-[1fr_repeat(11,_90px_20px)_90px_1fr]"
            >
              <div class="absolute w-[100vw] left-[-35px] md:left-[0] top-[0] h-[calc(100%+30px)] z-[-1]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head'
import footers from '@/components/foot'
export default {
  components: {
    headers,
    footers,
  },
}
</script>
